import logo from '../../logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route} from 'react-router-dom';

import Overview from '../overview/overview';
function App() {
	return (
		<div>
			<Overview />
		</div>
		// <BrowserRouter>
		// 	<Routes>
		// 		<Route exact path="/"  element={<Overview />} />
		// 	</Routes>
		// </BrowserRouter>
	);
}

export default App;
