import React, {useState, useEffect, useRef} from 'react';
import './sthGraph.css';
import {cycleOptions, sthBarOptions, sthOptions} from '../../../utils/dictionary';
import {Bar, Line} from 'react-chartjs-2';
import 'chartjs-adapter-moment';
import {
	Chart as ChartJS,
	TimeScale, //Import timescale instead of category for X axis
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
} from 'chart.js';

import {Dropdown} from '../../../components/Dropdown/Dropdown';
import {convertECtoPS, standardizedEC} from '../../../utils/generator';
import * as BsIcons from 'react-icons/bs';

import * as TbIcons from 'react-icons/tb';
import Histogram from '../../chart/histogram';
import {formatDateToString} from '../../../utils/textFormatter';

ChartJS.register(
	TimeScale, //Register timescale instead of category for X axis
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);

export const SthGraph = (props) => {

	const data = props.data;
	const [selectedMeasurement, setSelectedMeasurement] = useState({label:'Suhu Tanah',value:'soil_temperature'});
	const [barMeasurement, setBarMeasurement] = useState({label:'Suhu Tanah',value:'hist_soil_temperature'});
	const [barResponse, setBarResponse] = useState();
	const [barData, setBarData] = useState();
	const [barChart, setBarChart] = useState(<></>);

	const [chartData, setChartData] = useState();
	const [isBoth, setIsBoth] = useState(false);
	const [isSaline, setIsSaline] = useState(false);
	const [isTemp, setIsTemp] = useState(false);
	const [isAssigned, setIsAssigned] = useState(false);
	const [isRetrieved, setIsRetrieved] = useState(false);
	const sthPanelRef = useRef(null);

	const tempData = data['soil_temperature'];
	const moistureData = data['soil_moisture'];

	const airTempData = data['temperature'];
	const rhData = data['relative_humidity'];

	const ambData = data['ambient_light'];
	const conData = data['soil_conductivity'];

	const histSoilTemp = data['hist_soil_temperature'];
	const histSoilMoist = data['hist_soil_moisture'];
	const histAmbLight = data ['hist_ambient_light'];
	const histSoilCon = data['hist_soil_conductivity'];

	function rotateRight() {
		sthPanelRef.current.classList.toggle('sthSpinRight');
	}

	const generateBarData = (n) => {

		let barData;

		if (n === 'hist_soil_temperature') {
			barData = data[n].map((d) => ({state: `${d.x.toFixed(1)}°C`, frequency: d.y}));
		} else if (n === 'hist_soil_moisture') {
			barData = data[n].map((d) => ({state: `${d.x.toFixed(1)}%`, frequency: d.y}));
		}

		const dc = {
			labels: barData.map(function (d) {
				return d.state;
			}),
			datasets: [
				{
					label: `Frekuensi ${n}`,
					data: barData.map(function (d) {
						return d.frequency;
					}),
					backgroundColor: ['rgba(8, 154, 243, 1)']
				},
			]
		};

		setBarData(dc);
	};

	const generateLineChartData = (n)=>{
		if(n==='soil_and_air_temp'){
			const tmData = {
				labels: tempData.map(function (d) {
					return d.x;
				}),
				datasets: [
					{
						label: 'Suhu Tanah',
						data: tempData.map(function (d) {
							return d.y;
						}),
						yAxisID: 'ytemp',
						fill: false,
						backgroundColor: '#000000',
						borderColor: 'rgba(152, 245, 225,0.8)',
						borderWidth: 2,
						tension: 0.4,
						showTooltips: false,
						elements: {
							point: {
								radius: 2
							}
						}
					},
					{
						label: 'Suhu Udara',
						data: airTempData.map(function (d) {
							return d.y;
						}),
						yAxisID: 'yair',
						backgroundColor: '#000000',
						borderColor: 'rgba(129, 135, 220, 0.9)',
						borderWidth: 2,
						tension: 0.4,
						showTooltips: false,
						elements: {
							point: {
								radius: 2
							}
						}
					},

				]
			};
			setChartData(tmData);
			setIsBoth(true);
			setIsSaline(false);
			setIsTemp(false);
			setIsAssigned(true);
		}else if (n === 'soil_temperature') {
			const cData = {
				labels: tempData.map(function (d) {
					return d.x;
				}),
				datasets: [
					{
						label: selectedMeasurement.label,
						data: tempData.map(function (d) {
							return d.y;
						}),
						borderColor: 'rgba(152, 245, 225,0.8)',
						borderWidth: 2,
						tension: 0.4,
						showTooltips: false,
						elements: {
							point: {
								radius: 2
							}
						}
					},
				]
			};
			setChartData(cData);
			setIsBoth(false);
			setIsTemp(true);
			setIsAssigned(true);

		} else if (n === 'soil_moisture') {
			const cData = {
				labels: moistureData.map(function (d) {
					return d.x;
				}),
				datasets: [
					{
						label: selectedMeasurement.label,
						data: moistureData.map(function (d) {
							return d.y;
						}),
						borderColor: 'rgba(241, 192, 232,0.9)',
						borderWidth: 2,
						tension: 0.4,
						showTooltips: false,
						elements: {
							point: {
								radius: 2
							}
						}
					},
				]
			};
			setChartData(cData);
			setIsBoth(false);
			setIsTemp(false);
			setIsAssigned(true);
		} else if (n === 'soil_moisture_and_salinity') {
			const msData = {
				labels: moistureData.map(function (d) {
					return d.x;
				}),
				datasets: [
					{
						label: 'Kelembapan Tanah',
						data: moistureData.map(function (d) {
							return d.y;
						}),
						yAxisID: 'yMoist',
						borderColor: 'rgba(241, 192, 232,0.9)',
						borderWidth: 2,
						tension: 0.4,
						showTooltips: false,
						elements: {
							point: {
								radius: 2
							}
						}
					},
					{
						label: 'Konduktivitas Tanah',
						data: conData.map((d) => {
							return d.y;
						}),
						// data: convertECtoPS(conData),
						yAxisID: 'ySaline',
						borderColor: '#fad643',
						borderWidth: 2,
						tension: 0.4,
						showTooltips: false,
						elements: {
							point: {
								radius: 2
							}
						}
					}]
			};
			setChartData(msData);
			setIsBoth(true);
			setIsSaline(true);
			setIsTemp(false);
			setIsAssigned(true);
		}
	};



	useEffect(()=>{
		setIsRetrieved(false);
		generateLineChartData(selectedMeasurement.value);
		generateBarData(barMeasurement.value);


	},[selectedMeasurement.value, barMeasurement]);


	return(
		<div className={'SthPanelWrapper'}>
			<div className={'SthPanelContainer'}>
				<div className={'SthPanel'} ref={sthPanelRef}>
					<div className={'SthPanelContents PanelLine'}>
						<div className={'PanelSthTitle'}>
                        Indeks Tanah di Sumbul, Dairi
						</div>
						<div className={'PanelSthCaption'}>
                        Grafik {selectedMeasurement.label}
						</div>
						<div className={'SthPanelDepth'}>
							<div className={'PageTurner'}></div>
							{isAssigned && (
								<div className={'SthLineChart'}>
									{!isBoth && (isTemp && (
										<Line
											height="150px"
											width="350px"
											data={chartData}
											options={{
												responsive:false,
												maintainAspectRatio: false,
												scales: {
													x: {

														display: true,
														type: 'time',
														ticks: {
															color: '#FFFFFF',
														}
													},
													y: {
														display: true,
														ticks: {
															color: 'rgba(152, 245, 225,0.8)',
															callback: function(value, index, values) {
																return value + '°';
															}
														},
													},
												},
												plugins: {
													title: {
														display: false,
														text: 'Suhu Tanah',
														color:'#FFFFFF'
													},
													legend: {
														display:false,
														position: 'top',
														labels:{
															boxWidth:10,
															boxHeight:10,
														}
													},
												},

											}}
										/>
									))}
									{!isBoth && (!isTemp && (
										<Line
											height="150px"
											width="350px"
											data={chartData}
											options={{
												responsive:false,
												maintainAspectRatio: false,
												scales: {
													x: {

														display: true,
														type: 'time',
														ticks: {
															color: '#FFFFFF',
														}
													},
													y: {
														display: true,
														ticks: {
															color: 'rgba(241, 192, 232,0.9)',
															callback: function(value, index, values) {
																return value + '%';
															}
														},
													},
												},
												plugins: {
													title: {
														display: false,
														text: 'Suhu Tanah',
														color:'#FFFFFF'
													},
													legend: {
														display:false,
														position: 'top',
														labels:{
															boxWidth:10,
															boxHeight:10,
														}
													},
												},

											}}
										/>
									))}

									{isBoth && (!isSaline && (
										<Line
											data={chartData}
											height="150px"
											width="350px"
											options={{
												responsive: false,
												maintainAspectRatio: false,
												scales: {
													x: {

														display: true,
														type: 'time',
														ticks: {
															color: '#FFFFFF'
														}

													},
													ytemp: {
														display: true,
														position: 'left',
														ticks: {
															color: 'rgba(152, 245, 225,0.8)',
															callback: function(value, index, values) {
																return value + '°';
															}

														}
													},
													yair: {
														display: true,
														position: 'right',
														ticks: {
															color: 'rgba(200, 203, 249,0.9)',
															callback: function(value, index, values) {
																return value + '°';
															}
														},
														grid: {
															drawOnChartArea: false, // only want the grid lines for one axis to show up
														},
													},
												},
												plugins: {
													title: {
														display: false,
														text: 'Suhu Tanah',
														color:'#FFFFFF'
													},
													legend: {
														display:false,
														position: 'top',
														labels:{
															boxWidth:10,
															boxHeight:10,
														}
													},
												},


											}}
										/>
									))}
									{isBoth && (isSaline && (
										<Line
											data={chartData}
											height="150px"
											width="350px"
											options={{
												responsive: false,
												maintainAspectRatio: false,
												scales: {
													x: {

														display: true,
														type: 'time',
														ticks: {
															color: '#FFFFFF'
														}

													},
													yMoist: {
														display: true,
														position: 'left',
														ticks: {
															color: 'rgba(241, 192, 232,0.9)',
															callback: function(value, index, values) {
																return value + '%';
															}

														}
													},
													ySaline: {
														display: true,
														position: 'right',
														ticks: {
															color: '#fad643',
															callback: function(value, index, values) {
																return value + '';
															}
														},
														grid: {
															drawOnChartArea: false, // only want the grid lines for one axis to show up
														},
													},
												},
												plugins: {
													title: {
														display: false,
														color:'#FFFFFF'
													},
													legend: {
														display:false,
														position: 'top',
														labels:{
															boxWidth:10,
															boxHeight:10,
														}
													},
												},


											}}
										/>
									))}




								</div>
							)}
							<div className={'PageTurner'}>
								<BsIcons.BsFillCaretRightFill size={'28px'} opacity={'0.5'} onClick={rotateRight}/>
							</div>

						</div>
						<div className={'SthDropdownContainer'}>
							<Dropdown
								dropdownOptions={sthOptions}
								selected={selectedMeasurement}
								onSelectedChange={setSelectedMeasurement}
							/>
						</div>
					</div>
					<div className={'SthPanelContents PanelHist'}>
						<div className={'PanelSthTitle'}>
                            Indeks Tanah di Sumbul, Dairi
						</div>
						<div className={'PanelSthCaption'}>
                            Histogram {barMeasurement.label}
						</div>
						<div className={'SthPanelDepth'}>
							<div className={'PageTurner'}>
								<BsIcons.BsFillCaretLeftFill size={'28px'} opacity={'0.5'} onClick={rotateRight}/>
							</div>
							{isAssigned && (
								<div className={'SthBarChart'}>
									<Bar className={'BarChart'}
										height="100%"
										width="100%"
										data={barData}
										options={{
											responsive: true,
											maintainAspectRatio: false,
											scales: {
												x: {
													display:true,
													ticks: {
														color:'#FFFFFF'
													}

												},
												y: {
													display:true,
													ticks: {
														color:'#FFFFFF'
													}

												}
											},
											plugins: {

												title: {
													display: false,
													color:'#FFFFFF'

												},
												legend: {
													display: false,
													position: 'bottom'
												}
											}
										}}
									/>
								</div>
							)}
							<div className={'PageTurner'}>

							</div>

						</div>
						<div className={'SthDropdownContainer'}>
							<Dropdown
								dropdownOptions={sthBarOptions}
								selected={barMeasurement}
								onSelectedChange={setBarMeasurement}
							/>
						</div>
					</div>
				</div>

			</div>
		</div>
	);
};