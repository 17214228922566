import './weatherTimeContent.css';
import {epochDict, wCaption} from '../../../../utils/dictionary';
import {weatherSvgDict} from '../../../../utils/dictionary';
import {formatDateToString, formatUnits, getDayAndDate} from '../../../../utils/textFormatter';
import {useEffect, useState} from 'react';

const WeatherTimeContent = (props) => {

	const data = props.data;



	const [dateKey, setDateKey] = useState('');
	const [timeKey, setTimeKey] = useState('');
	const [widgetTitle, setWidgetTitle] = useState('');

	const [weatherIcon, setWeatherIcon] = useState();
	const [weatherCaption, setWeatherCaption] = useState();
	const [nTemp, setNTemp] = useState(0);
	const [nHumid, setNHumid] = useState(0);
	const [nCuaca, setNCuaca] = useState('');
	const [tempStr, setTempStr] = useState('');
	const [humidStr, setHumidStr] = useState('');

	const [dateStr, setDateStr] = useState('');

	const [isAssigned, setIsAssigned] = useState(false);
	const [isDateSet, setIsDateSet] = useState(false);


	useEffect(() => {

		if(isDateSet){

			setNTemp(data['data'][dateKey][epochDict[props.time]['time']]['Temperature']);
			setNHumid(data['data'][dateKey][epochDict[props.time]['time']]['Humidity']);
			setNCuaca(data['data'][dateKey][epochDict[props.time]['time']]['Weather']);
			setWeatherIcon(weatherSvgDict[nCuaca]);
			setWeatherCaption(wCaption[nCuaca]);
			setTempStr(formatUnits('temperature', nTemp));
			setHumidStr(formatUnits('humidity', nHumid));


		}
		else if(!isDateSet){

			if (props.day === 'today') {
				const d1 = new Date();
				setDateKey(formatDateToString(new Date()));
				setDateStr(getDayAndDate(d1));
			} else if (props.day === 'tommorrow') {
				const d2 = new Date();
				d2.setDate(d2.getDate() + 1);
				setDateKey(formatDateToString(d2));
				setDateStr(getDayAndDate(d2));
			}

			setWidgetTitle(epochDict[props.time]['text']);
			setTimeKey(epochDict[props.time]['time']);

			setIsDateSet(true);
		}
		setIsAssigned(true);

	}, [data, dateKey, isDateSet, nCuaca, nHumid, nTemp, props.time, props.day]);

	return(
		<div className={'TimeWeatherContainer'}>
			{isAssigned && (
				<div className={'TimeWeatherContainer'}>
					<div className={'WeatherTitleContainer'}>
						<div className={'WidgetTitle'}>
							{widgetTitle}
						</div>

						<div className={'WidgetDayTitle'}>
							{dateStr}
						</div>
					</div>
					<div className={'WeatherIconCaptionWrapper'}>
						<div className={'WeatherIcon'}>
							{weatherIcon}
						</div>

						<div className={'WeatherCaption'}>
							{weatherCaption}
						</div>
					</div>

					<div className={'WeatherInfo'}>
						<div className={'WeatherInfoBackground'}>
							<div className={'nInfo'}>
								{tempStr} / {humidStr}
							</div>
						</div>
					</div>
				</div>
			)}

		</div>
	);
};

export default WeatherTimeContent;