import BawangMerah from '../static/icons/commodities/BawangMerah';
import BawangPutih from '../static/icons/commodities/BawangPutih';
import Beras from '../static/icons/commodities/Beras';
import CabaiMerah from '../static/icons/commodities/CabaiMerah';
import CabaiRawit from '../static/icons/commodities/CabaiRawit';
import DagingAyam from '../static/icons/commodities/DagingAyam';
import DagingSapi from '../static/icons/commodities/DagingSapi';
import GulaPasir from '../static/icons/commodities/GulaPasir';
import MinyakGoreng from '../static/icons/commodities/MinyakGoreng';
import TelurAyam from '../static/icons/commodities/TelurAyam';
import {CerahMalam, CerahPagi, CerahBerawanMalam, CerahBerawanPagi,
	BerawanMalam, BerawanPagi, BerawanTebalMalam,BerawanTebalPagi,
	KabutPagi,KabutMalam,HujanRinganMalam,HujanRinganPagi,HujanLebat,
	HujanSedang} from '../static/icons/weather';
import Histogram from '../views/chart/histogram';
import React from 'react';



export const comColor ={
	'bawang_merah' : ['rgba(4, 80, 180, 1)'],
	'bawang_putih' : ['rgba(4, 109, 200,1)'],
	'cabai_merah' : ['rgba(17, 132, 167, 1)'],
	'cabai_rawit' : ['rgba(21, 162, 162,1)'],
	'daging_ayam' : ['rgba(111, 177, 160,1)'],
	'daging_sapi' : ['rgba(180, 65, 142,1)'],
	'telur_ayam' : ['rgba(217, 74, 140,1)'],
	'minyak_goreng' : ['rgba(234, 81, 95,1)'],
	'gula_pasir' : ['rgba(254, 116, 52,1)'],
	'beras' : ['rgba(254, 168, 2,1)'],

};

export const comColorAlpha ={
	'bawang_merah' : ['rgba(4, 80, 180, 0.6)'],
	'bawang_putih' : ['rgba(4, 109, 200,0.6)'],
	'cabai_merah' : ['rgba(17, 132, 167, 0.6)'],
	'cabai_rawit' : ['rgba(21, 162, 162,0.6)'],
	'daging_ayam' : ['rgba(111, 177, 160,0.6)'],
	'daging_sapi' : ['rgba(180, 65, 142,0.6)'],
	'telur_ayam' : ['rgba(217, 74, 140,0.6)'],
	'minyak_goreng' : ['rgba(234, 81, 95,0.6)'],
	'gula_pasir' : ['rgba(254, 116, 52,0.6)'],
	'beras' : ['rgba(254, 168, 2,0.6)'],

};

export const cDict =
    {'kab_banyumas': 'Kabupaten Banyumas',
    	'kab_banyuwangi': 'Kabupaten Banyuwangi',
    	'kab_boyolali': 'Kabupaten Boyolali',
    	'kab_bulukumba': 'Kabupaten Bulukumba',
    	'kab_bulungan': 'Kabupaten Bulungan',
    	'kab_bungo': 'Kabupaten Bungo',
    	'kab_cilacap': 'Kabupaten Cilacap',
    	'kab_cirebon': 'Kabupaten Cirebon',
    	'kab_jember': 'Kabupaten Jember',
    	'kab_karanganyar': 'Kabupaten Karanganyar',
    	'kab_klaten': 'Kabupaten Klaten',
    	'kab_kotabaru': 'Kabupaten Kotabaru',
    	'kab_kudus': 'Kabupaten Kudus',
    	'kab_lombok_timur': 'Kabupaten Lombok Timur',
    	'kab_majene': 'Kabupaten Majene',
    	'kab_mamuju': 'Kabupaten Mamuju',
    	'kab_manokwari': 'Kabupaten Manokwari',
    	'kab_merauke': 'Kabupaten Merauke',
    	'kab_mimika': 'Kabupaten Mimika',
    	'kab_polewali_mandar': 'Kabupaten Polewali Mandar',
    	'kab_sintang': 'Kabupaten Sintang',
    	'kab_sragen': 'Kabupaten Sragen',
    	'kab_sukoharjo': 'Kabupaten Sukoharjo',
    	'kab_sumba_timur': 'Kabupaten Sumba Timur',
    	'kab_sumbawa': 'Kabupaten Sumbawa',
    	'kab_sumenep': 'Kabupaten Sumenep',
    	'kab_tasikmalaya': 'Kabupaten Tasikmalaya',
    	'kab_wonogiri': 'Kabupaten wonogiri',
    	'kota_ambon': 'kota_ambon',
    	'kota_balikpapan': 'kota_balikpapan',
    	'kota_banda_aceh': 'Kota Banda Aceh',
    	'kota_bandar_lampung': 'Kota Bandar Lampung',
    	'kota_bandung': 'Kota Bandung',
    	'kota_banjarmasin': 'Kota Banjarmasin',
    	'kota_batam': 'Kota Batam',
    	'kota_bau_bau': 'Kota Bau Bau',
    	'kota_bekasi': 'Kota Bekasi',
    	'kota_bengkulu': 'Kota Bengkulu',
    	'kota_bima': 'Kota Bima',
    	'kota_blitar': 'Kota Blitar',
    	'kota_bogor': 'Kota Bogor',
    	'kota_bone': 'Kota Bone',
    	'kota_bontang': 'Kota Bontang',
    	'kota_bukittinggi': 'Kota Bukittinggi',
    	'kota_cilegon': 'kota_cilegon',
    	'kota_cirebon': 'Kota Cirebon',
    	'kota_denpasar': 'Kota Denpasar',
    	'kota_depok': 'Kota Depok',
    	'kota_dumai': 'Kota Dumai',
    	'kota_gorontalo': 'Kota Gorontalo',
    	'kota_gunung_sitoli': 'Kota Gunung Sitoli',
    	'kota_jakarta_pusat': 'Kota Jakarta Pusat',
    	'kota_jambi': 'Kota Jambi',
    	'kota_jayapura': 'Kota Jayapura',
    	'kota_kediri': 'Kota Kediri',
    	'kota_kendari': 'Kota Kendari',
    	'kota_kotamobagu': 'Kota Kotamobagu',
    	'kota_kupang': 'Kota Kupang',
    	'kota_lhokseumawe': 'Kota Lhokseumawe',
    	'kota_lubuk_linggau': 'Kota Lubuk Linggau',
    	'kota_madiun': 'Kota Madiun',
    	'kota_makassar': 'Kota Makassar',
    	'kota_malang': 'Kota Malang',
    	'kota_mamuju': 'Kota Mamuju',
    	'kota_manado': 'Kota Manado',
    	'kota_mataram': 'Kota Mataram',
    	'kota_maumere': 'Kota Maumere',
    	'kota_medan': 'Kota Medan',
    	'kota_metro': 'Kota Metro',
    	'kota_meulaboh': 'Kota Meulaboh',
    	'kota_padang': 'Kota Padang',
    	'kota_padang_sidempuan': 'Kota Padang Sidempuan',
    	'kota_palangkaraya': 'Kota Palangkaraya',
    	'kota_palembang': 'Kota Palembang',
    	'kota_palopo': 'Kota Palopo',
    	'kota_palu': 'Kota Palu',
    	'kota_pangkal_pinang': 'Kota Pangkal Pinang',
    	'kota_parepare': 'Kota Pare Pare',
    	'kota_pekanbaru': 'Kota Pekanbaru',
    	'kota_pematang_siantar': 'Kota Pematang Siantar',
    	'kota_pontianak': 'Kota Pontianak',
    	'kota_probolinggo': 'Kota Probolinggo',
    	'kota_samarinda': 'Kota Samarinda',
    	'kota_sampit': 'Kota Sampit',
    	'kota_semarang': 'Kota Semarang',
    	'kota_serang': 'Kota Serang',
    	'kota_sibolga': 'Kota Sibolga',
    	'kota_singaraja': 'Kota Singaraja',
    	'kota_singkawang': 'Kota Singkawang',
    	'kota_sorong': 'Kota Sorong',
    	'kota_sukabumi': 'Kota Sukabumi',
    	'kota_surabaya': 'Kota Surabaya',
    	'kota_surakarta': 'Kota Surakarta',
    	'kota_surakarta_solo': 'Kota Solo',
    	'kota_tangerang': 'Kota Tangerang',
    	'kota_tanjung': 'Kota Tanjung',
    	'kota_tanjung_pandan': 'Kota Tanjung Pandan',
    	'kota_tanjung_pinang': 'Kota Tanjung Pinang',
    	'kota_tarakan': 'Kota Tarakan',
    	'kota_tasikmalaya': 'Kota Tasikmalaya',
    	'kota_tegal': 'Kota Tegal',
    	'kota_tembilahan': 'Kota Tembilahan',
    	'kota_ternate': 'Kota Ternate',
    	'kota_tual': 'Kota Tual',
    	'kota_yogyakarta': 'Kota Yogyakarta'};

export const pDict ={'aceh': 'Aceh',
	'bali': 'Bali',
	'banten': 'Banten',
	'bengkulu': 'Bengkulu',
	'di_yogyakarta': 'DI Yogyakarta',
	'dki_jakarta': 'DKI Jakarta',
	'gorontalo': 'Gorontalo',
	'jambi': 'Jambi',
	'jawa_barat': 'Jawa Barat',
	'jawa_tengah': 'Jawa Tengah',
	'jawa_timur': 'Jawa Timur',
	'kalimantan_barat': 'Kalimantan Barat',
	'kalimantan_selatan': 'Kalimantan Selatan',
	'kalimantan_tengah': 'Kalimantan Tengah',
	'kalimantan_timur': 'Kalimantan Timur',
	'kalimantan_utara': 'Kalimantan Utara',
	'kepulauan_bangka_belitung': 'Kepulauan Bangka Belitung',
	'kepulauan_riau': 'Kepulauan Riau',
	'lampung': 'Lampung',
	'maluku': 'Maluku',
	'maluku_utara': 'Maluku Utara',
	'nusa_tenggara_barat': 'Nusa Tenggara Barat',
	'nusa_tenggara_timur': 'Nusa Tenggara Timur',
	'papua': 'Papua',
	'papua_barat': 'Papua Barat',
	'riau': 'Riau',
	'sulawesi_barat': 'Sulawesi Barat',
	'sulawesi_selatan': 'Sulawesi Selatan',
	'sulawesi_tengah': 'Sulawesi Tengah',
	'sulawesi_tenggara': 'Sulawesi Tenggara',
	'sulawesi_utara': 'Sulawesi Utara',
	'sumatera_barat': 'Sumatera Barat',
	'sumatera_selatan': 'Sumatera Selatan',
	'sumatera_utara': 'Sumatera Utara'};

export const comDict = {
	'bawang_merah' : 'Bawang Merah',
	'bawang_putih' : 'Bawang Putih',
	'beras' : 'Beras',
	'cabai_merah' : 'Cabai Merah',
	'cabai_rawit' : 'Cabai Rawit',
	'daging_ayam' : 'Daging Ayam',
	'daging_sapi' : 'Daging Sapi',
	'gula_pasir' : 'Gula Pasir',
	'minyak_goreng' : 'Minyak Goreng',
	'telur_ayam' : 'Telur Ayam'
};

export const svgDict = {
	'bawang_merah' : <BawangMerah height={'5rem'} width={'5rem'}/>,
	'bawang_putih' : <BawangPutih height={'5rem'} width={'5rem'}/>,
	'beras' : <Beras height={'5rem'} width={'5rem'}/>,
	'cabai_merah' : <CabaiMerah height={'5rem'} width={'5rem'}/>,
	'cabai_rawit' : <CabaiRawit height={'5rem'} width={'5rem'}/>,
	'daging_ayam' : <DagingAyam height={'5rem'} width={'5rem'}/>,
	'daging_sapi' : <DagingSapi height={'5rem'} width={'5rem'}/>,
	'gula_pasir' : <GulaPasir height={'5rem'} width={'5rem'}/>,
	'minyak_goreng' : <MinyakGoreng  height={'5rem'} width={'5rem'}/>,
	'telur_ayam' : <TelurAyam height={'5rem'} width={'5rem'}/>
};

export const epochDict = {
	'dini' : {'time' : '0', 'text':'Dini Hari', 'background': 'linear-gradient(218.1deg, #003F88 15.96%, #000000 77.3%)'},
	'pagi' : {'time' : '6', 'text':'Pagi Hari', 'background': 'linear-gradient(218.1deg, #FDC500 15.96%, #003F88 77.3%)'},
	'siang' : {'time' : '12', 'text':'Siang Hari', 'background': 'linear-gradient(218.1deg, #FF6868 15.96%, #FDC500 77.3%)'},
	'malam' : {'time' : '18', 'text':'Malam Hari', 'background': 'linear-gradient(47.18deg, #883232 17.62%, #032B5A 41.33%, #000000 80.82%)'},
};
export const weatherSvgDict = {
	'0_cerah': <CerahMalam height={'5rem'} width={'5rem'}/>,
	'6_cerah': <CerahPagi height={'5rem'} width={'5rem'}/>,
	'12_cerah': <CerahPagi height={'5rem'} width={'5rem'}/>,
	'18_cerah': <CerahMalam height={'5rem'} width={'5rem'}/>,

	'0_cerah_berawan': <CerahBerawanMalam height={'5rem'} width={'5rem'}/>,
	'6_cerah_berawan': <CerahBerawanPagi height={'5rem'} width={'5rem'}/>,
	'12_cerah_berawan': <CerahBerawanPagi height={'5rem'} width={'5rem'}/>,
	'18_cerah_berawan': <CerahBerawanMalam height={'5rem'} width={'5rem'}/>,

	'0_berawan': <BerawanMalam height={'5rem'} width={'5rem'}/>,
	'6_berawan': <BerawanPagi height={'5rem'} width={'5rem'}/>,
	'12_berawan': <BerawanPagi height={'5rem'} width={'5rem'}/>,
	'18_berawan': <BerawanMalam height={'5rem'} width={'5rem'}/>,

	'0_berawan_tebal': <BerawanTebalMalam height={'5rem'} width={'5rem'}/>,
	'6_berawan_tebal': <BerawanTebalPagi height={'5rem'} width={'5rem'}/>,
	'12_berawan_tebal': <BerawanTebalPagi height={'5rem'} width={'5rem'}/>,
	'18_berawan_tebal': <BerawanTebalMalam height={'5rem'} width={'5rem'}/>,

	'0_kabut': <KabutMalam height={'5rem'} width={'5rem'}/>,
	'6_kabut': <KabutPagi height={'5rem'} width={'5rem'}/>,
	'12_kabut': <KabutPagi height={'5rem'} width={'5rem'}/>,
	'18_kabut': <KabutMalam height={'5rem'} width={'5rem'}/>,

	'0_hujan_ringan': <HujanRinganMalam height={'5rem'} width={'5rem'}/>,
	'6_hujan_ringan': <HujanRinganPagi height={'5rem'} width={'5rem'}/>,
	'12_hujan_ringan': <HujanRinganPagi height={'5rem'} width={'5rem'}/>,
	'18_hujan_ringan': <HujanRinganMalam height={'5rem'} width={'5rem'}/>,

	'0_hujan_sedang': <HujanSedang height={'5rem'} width={'5rem'}/>,
	'6_hujan_sedang': <HujanSedang height={'5rem'} width={'5rem'}/>,
	'12_hujan_sedang': <HujanSedang height={'5rem'} width={'5rem'}/>,
	'18_hujan_sedang': <HujanSedang height={'5rem'} width={'5rem'}/>,

	'0_hujan_lebat': <HujanLebat height={'5rem'} width={'5rem'}/>,
	'6_hujan_lebat': <HujanLebat height={'5rem'} width={'5rem'}/>,
	'12_hujan_lebat': <HujanLebat height={'5rem'} width={'5rem'}/>,
	'18_hujan_lebat': <HujanLebat height={'5rem'} width={'5rem'}/>,
};

export const wCaption = {
	'0_cerah':'Cerah',
	'6_cerah':'Cerah',
	'12_cerah':'Cerah',
	'18_cerah':'Cerah',

	'0_cerah_berawan':'Cerah Berawan',
	'6_cerah_berawan':'Cerah Berawan',
	'12_cerah_berawan':'Cerah Berawan',
	'18_cerah_berawan':'Cerah Berawan',

	'0_berawan':'Berawan',
	'6_berawan':'Berawan',
	'12_berawan':'Berawan',
	'18_berawan':'Berawan',

	'0_berawan_tebal':'Berawan Tebal',
	'6_berawan_tebal':'Berawan Tebal',
	'12_berawan_tebal':'Berawan Tebal',
	'18_berawan_tebal':'Berawan Tebal',

	'0_kabut':'Berkabut',
	'6_kabut':'Berkabut',
	'12_kabut':'Berkabut',
	'18_kabut':'Berkabut',

	'0_hujan_ringan':'iHujan Ringan',
	'6_hujan_ringan':'Hujan Ringan',
	'12_hujan_ringan':'Hujan Ringan',
	'18_hujan_ringan':'Hujan Ringan',

	'0_hujan_sedang': 'Hujan Sedang',
	'6_hujan_sedang': 'Hujan Sedang',
	'12_hujan_sedang': 'Hujan Sedang',
	'18_hujan_sedang':'Hujan Sedang',

	'0_hujan_lebat':'Hujan Lebat',
	'6_hujan_lebat':'Hujan Lebat',
	'12_hujan_lebat':'Hujan Lebat',
	'18_hujan_lebat':'Hujan Lebat',
};

export const cycleOptions = [
	{label: 'Suhu Udara', value: 'temperature'},
	{label: 'Kelembapan Udara', value: 'humidity'},
	{label: 'Kecepatan Angin', value: 'wind_speed'}
];

export const sthOptions = [
	{label: 'Suhu Tanah', value: 'soil_temperature'},
	{label: 'Kelembapan Tanah', value: 'soil_moisture'},
	{label: 'Suhu Udara dan Tanah', value: 'soil_and_air_temp'},
	{label: 'Kelembapan dan Salinitas Tanah', value: 'soil_moisture_and_salinity'}
];

export const sthBarOptions = [
	{label: 'Suhu Tanah', value: 'hist_soil_temperature'},
	{label: 'Kelembapan Tanah', value: 'hist_soil_moisture'},
];

export const sthBarView = {
	'soil_temperature': <Histogram measurement={'soil_temperature'} sensor_id={'node_2'} />,
	'soil_moisture': <Histogram measurement={'soil_moisture'} sensor_id={'node_2'} />
};

export const ambOptions = [
	{label: 'Cahaya Sekitar', value: 'ambient_light'},
	{label: 'Voltase Matahari', value: 'solar_voltage'}
];

export const ambBarOptions = [
	{label: 'Cahaya Sekitar', value: 'hist_ambient_light'},
	{label: 'Voltase Matahari', value: 'hist_solar_voltage'}
];