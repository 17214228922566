import React, {useState, useEffect, useRef} from 'react';
import './ambientPanel.css';
import {ambBarOptions, ambOptions, cycleOptions, sthBarOptions, sthOptions} from '../../../utils/dictionary';
import {Bar, Line} from 'react-chartjs-2';
import 'chartjs-adapter-moment';
import {
	Chart as ChartJS,
	TimeScale, //Import timescale instead of category for X axis
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
} from 'chart.js';

import {Dropdown} from '../../../components/Dropdown/Dropdown';
import {convertECtoPS, standardizedEC} from '../../../utils/generator';
import * as BsIcons from 'react-icons/bs';

import * as TbIcons from 'react-icons/tb';
import Histogram from '../../chart/histogram';
import {formatDateToString} from '../../../utils/textFormatter';

ChartJS.register(
	TimeScale, //Register timescale instead of category for X axis
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);

export const AmbGraph = (props) => {

	const data = props.data;
	const [selectedMeasurement, setSelectedMeasurement] = useState({label:'Cahaya Sekitar',value:'ambient_light'});
	const [barMeasurement, setBarMeasurement] = useState({label:'Cahaya Sekitar',value:'hist_ambient_light'});
	const [barResponse, setBarResponse] = useState();
	const [barData, setBarData] = useState();
	const [barChart, setBarChart] = useState(<></>);

	const [chartData, setChartData] = useState();
	const [isAmb, setIsAmb] = useState(false);
	const [isSaline, setIsSaline] = useState(false);
	const [isTemp, setIsTemp] = useState(false);
	const [isAssigned, setIsAssigned] = useState(false);
	const [isRetrieved, setIsRetrieved] = useState(false);
	const ambPanelRef = useRef(null);

	const tempData = data['soil_temperature'];
	const moistureData = data['soil_moisture'];

	const airTempData = data['temperature'];
	const rhData = data['relative_humidity'];

	const ambData = data['ambient_light'];
	const conData = data['soil_conductivity'];
	const solarVoltData = data['solar_voltage'];

	const histSoilTemp = data['hist_soil_temperature'];
	const histSoilMoist = data['hist_soil_moisture'];
	const histAmbLight = data ['hist_ambient_light'];
	const histSoilCon = data['hist_soil_conductivity'];
	const histSolarVolt = data['hist_solar_voltage'];

	function rotateRight() {
		ambPanelRef.current.classList.toggle('ambSpinRight');
	}

	const generateBarData = (n) => {

		let barData;

		if (n === 'hist_ambient_light') {
			barData = data[n].map((d) => ({state: `${d.x.toFixed(1)}°C`, frequency: d.y}));
		} else if (n === 'hist_solar_voltage') {
			barData = data[n].map((d) => ({state: `${d.x.toFixed(1)}%`, frequency: d.y}));
		}

		const dc = {
			labels: barData.map(function (d) {
				return d.state;
			}),
			datasets: [
				{
					label: `Frekuensi ${n}`,
					data: barData.map(function (d) {
						return d.frequency;
					}),
					backgroundColor: ['rgba(8, 154, 243, 1)']
				},
			]
		};

		setBarData(dc);
	};
	const generateLineChartData = (n)=>{
		if(n==='ambient_light'){
			const tmData = {
				labels: ambData.map(function (d) {
					return d.x;
				}),
				datasets: [
					{
						label: 'Cahaya Sekitar',
						data: ambData.map(function (d) {
							return d.y;
						}),
						fill: false,
						backgroundColor: '#000000',
						borderColor: 'rgba(255, 180, 162,0.8)',
						borderWidth: 2,
						tension: 0.4,
						showTooltips: false,
						elements: {
							point: {
								radius: 2
							}
						}
					},
				]
			};
			setChartData(tmData);
			setIsAmb(true);
			setIsAssigned(true);
		}else if (n === 'solar_voltage') {
			const cData = {
				labels: solarVoltData.map(function (d) {
					return d.x;
				}),
				datasets: [
					{
						label: selectedMeasurement.label,
						data: solarVoltData.map(function (d) {
							return d.y;
						}),
						borderColor: 'rgba(92, 149, 255,0.8)',
						borderWidth: 2,
						tension: 0.4,
						showTooltips: false,
						elements: {
							point: {
								radius: 2
							}
						}
					},
				]
			};
			setChartData(cData);
			setIsAmb(false);
			setIsAssigned(true);
		}
	};



	useEffect(()=>{
		setIsRetrieved(false);
		generateLineChartData(selectedMeasurement.value);
		generateBarData(barMeasurement.value);


	},[selectedMeasurement.value, barMeasurement]);


	return(
		<div className={'AmbPanelWrapper'}>
			<div className={'AmbPanelContainer'}>
				<div className={'AmbPanel'} ref={ambPanelRef}>
					<div className={'AmbPanelContents PanelLine'}>
						<div className={'PanelAmbTitle'}>
                            Indeks Pencahayaan di Sumbul, Dairi
						</div>
						<div className={'PanelAmbCaption'}>
                            Grafik {selectedMeasurement.label}
						</div>
						<div className={'AmbPanelDepth'}>
							<div className={'PageTurner'}></div>
							{isAssigned && (
								<div className={'AmbLineChart'}>
									{isAmb && (
										<Line
											data={chartData}
											height="150px"
											width="350px"
											options={{
												responsive: false,
												maintainAspectRatio: false,
												scales: {
													x: {

														display: true,
														type: 'time',
														ticks: {
															color: '#FFFFFF'
														}

													},
													y: {
														display: true,
														position: 'left',
														ticks: {
															color: 'rgba(255, 180, 162,0.9)',
															callback: function(value, index, values) {
																return value + 'lux';
															}

														}
													},
												},
												plugins: {
													title: {
														display: false,
														color:'#FFFFFF'
													},
													legend: {
														display:false,
														position: 'top',
														labels:{
															boxWidth:10,
															boxHeight:10,
														}
													},
												},


											}}
										/>
									)}

									{!isAmb && (
										<Line
											data={chartData}
											height="150px"
											width="350px"
											options={{
												responsive: false,
												maintainAspectRatio: false,
												scales: {
													x: {

														display: true,
														type: 'time',
														ticks: {
															color: '#FFFFFF'
														}

													},
													y: {
														display: true,
														position: 'left',
														ticks: {
															color: 'rgba(92, 149, 255,0.9)',
															callback: function(value, index, values) {
																return value + ' V';
															}

														}
													},
												},
												plugins: {
													title: {
														display: false,
														color:'#FFFFFF'
													},
													legend: {
														display:false,
														position: 'top',
														labels:{
															boxWidth:10,
															boxHeight:10,
														}
													},
												},


											}}
										/>
									)}




								</div>
							)}
							<div className={'PageTurner'}>
								<BsIcons.BsFillCaretRightFill size={'28px'} opacity={'0.5'} onClick={rotateRight}/>
							</div>

						</div>
						<div className={'AmbDropdownContainer'}>
							<Dropdown
								dropdownOptions={ambOptions}
								selected={selectedMeasurement}
								onSelectedChange={setSelectedMeasurement}
							/>
						</div>
					</div>
					<div className={'AmbPanelContents PanelHist'}>
						<div className={'PanelAmbTitle'}>
                            Indeks Pencahayaan di Sumbul, Dairi
						</div>
						<div className={'PanelAmbCaption'}>
                            Histogram {barMeasurement.label}
						</div>
						<div className={'AmbPanelDepth'}>
							<div className={'PageTurner'}>
								<BsIcons.BsFillCaretLeftFill size={'28px'} opacity={'0.5'} onClick={rotateRight}/>
							</div>
							{isAssigned && (
								<div className={'AmbBarChart'}>
									<Bar className={'BarChart'}
										height="100%"
										width="100%"
										data={barData}
										options={{
											responsive: true,
											maintainAspectRatio: false,
											scales: {
												x: {
													display:true,
													ticks: {
														color:'#FFFFFF'
													}

												},
												y: {
													display:true,
													ticks: {
														color:'#FFFFFF'
													}

												}
											},
											plugins: {

												title: {
													display: false,
													color:'#FFFFFF'

												},
												legend: {
													display: false,
													position: 'bottom'
												}
											}
										}}
									/>
								</div>
							)}
							<div className={'PageTurner'}>

							</div>

						</div>
						<div className={'AmbDropdownContainer'}>
							<Dropdown
								dropdownOptions={ambBarOptions}
								selected={barMeasurement}
								onSelectedChange={setBarMeasurement}
							/>
						</div>
					</div>
				</div>

			</div>
		</div>
	);
};